import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Despre Noi
                                </span>
                                <h2>Schimbă modul în care faci afaceri cu soluții digitale</h2>
                                <p>Vertical Digital este o companie de servicii software cu sediul central la Oradea specializată în dezvoltarea de aplicații IT pentru afaceri.</p>
                                
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>6 Ani</h3>
                                        <p>De funcționare</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>30+</h3>
                                        <p>Colegi în echipă</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfacția clienților</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>80%</h3>
                                        <p>Creștere an de an</p>
                                    </li>
                                </ul>
                                <p>Principala noastră sursă de succes este succesul clienților noștri. În calitate de partener care lucrează cu tine, ne dorim să
                                   contribuim la succesul afacerii tale. Pentru asta suntem prezenți la fiecare etapă din dezvoltarea proiectelor.</p>
                                
                                <Link to="/contact/" className="default-btn">
                                    <i className="flaticon-right"></i>Discută cu noi<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Soluții</h3>
                                <p>Transformăm afacerile transformând tehnologia, câte o soluție pe rând.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Realizare de produse software</li>
                                    <li><i className="flaticon-tick"></i> Echipe dedicate</li>
                                    <li><i className="flaticon-tick"></i> Consultanță IT</li>
                                    <li><i className="flaticon-tick"></i> Cursuri specializare </li>
                                    <li><i className="flaticon-tick"></i> Recrutare IT </li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Domenii</h3>
                                <p>Dezvoltăm și integrăm pe verticală soluții de digitalizare pentru diverse domenii.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Transport si logistică</li>
                                    <li><i className="flaticon-tick"></i> Fintech & banking</li>
                                    <li><i className="flaticon-tick"></i> Ospitalitate si turism</li>
                                    <li><i className="flaticon-tick"></i> Producție industrială</li>
                                    <li><i className="flaticon-tick"></i> Retail online</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Tehnologii</h3>
                                <p>Stăpânim o arie largă de tehnologii pentru a fii flexibili și agili în abordarea proiectelor.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Java</li>
                                    <li><i className="flaticon-tick"></i> SAP</li>
                                    <li><i className="flaticon-tick"></i> C#</li>
                                    <li><i className="flaticon-tick"></i> SQL</li>
                                    <li><i className="flaticon-tick"></i> Javascript</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;