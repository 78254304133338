import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" />
                        Testimoniale
                    </span>
                    <h2>Ce spun clienții noștri?</h2>
                    <p>Aflați de ce clienții noștri se bucură că ne-au întalnit.</p>
                </div>

                {display ? <OwlCarousel
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-testimonials-item">
                        <p>Echipa Vertical Digital a fost esențială pentru construirea, lansarea și creșterea platformelor noastre de închirieri si rezervări. Cu ei am avut tot timpul alături un partener de încredere, loial si dedicat succesului nostru.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Dan Morar</h3>
                                    <span>CEO topcarrentals.ro</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Vertical Digital is a great partner for us. They helped us find the best solution for our project and supported us in all steps of the journey.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Gregory Bihn</h3>
                                    <span>CEO at PetroED</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>I would highly recommend their services to any company looking for top-notch IT staff. They have truly been a valuable partner for our business and have played an important role in our success.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="title">
                                    <h3>Levin Wense</h3>
                                    <span>CEO and Co-founder @ MVP Match</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}


            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;